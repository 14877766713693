.hero-heading p{
    font-size: clamp(2rem,5vw,4rem) !important;
    line-height: 1 !important;
    margin: 0 !important;
    top: 30%;
    left: 10%;
}
.aftr-hero{
    display: flex;
}
.our-university-logos .slick-arrow{
    position: absolute;
    top: 220%;
    left: 0;
}
.st0{
    transition: 0.2s;
}
.st1{
    transition: 0.2s;
}
.slideBtn:hover .st1{
    fill: #DB3656 !important;
}
.slideBtn:hover .st0{
    stroke: #FFFFFF !important;
}
.afterHeroSlider .slick-next{
    left: 10%;
}
.slick-next::before{
    display: none;
}
.slick-prev::before{
    display: none;
}
.ataglancebox{
    width: 85% !important;
    aspect-ratio: 1/0.2 !important;
    align-items: center;
    justify-content: center;
}
.wwsgm{
    padding: 0 !important;
    display: flex;
}

.wwsgm-right{
    width: 40% !important;
    align-items: start;
    padding-right: 4%;
}
.wwsgm-img{
    width: 80% !important;
}
/* .our-partners-logo{
    width: 100% !important;
    padding: 0 2%;
} */
.globeDiv{
    width: 50%;
    aspect-ratio: 1/0.7;
    display: flex;
    align-items: center;
    justify-content: center;
}
.globe{
    width: 80%;
    height: 70%;
    border-radius: 10%;
    position: relative;
}
.globeItem{
    position: absolute !important;
    margin: 0 !important;
    top: 0;
    left: 0;
}
.ma-list{
    width: 100% !important;
}
.ma-list-row{
    width: 90% !important;
}
.aag-box{
    /* transform: scale(0.9) !important; */
    box-shadow: 0px 31px 70px rgb(170 196 205 / 71%) !important;

}
@media (max-width:1000px) {
    .aftr-hero{
        flex-direction: column;
    }
    .aftr-hero-rec{
        width:100% !important;
    }
    .afterHeroSlider{
        width: 100% !important;
        margin-top: 5%;
    }
}
.ataglancebox{
    display: flex;
    flex-wrap: wrap;
    row-gap: 1rem;
    justify-content: space-between;
    margin-top: 1rem !important;
}
@media (max-width:1070px) {
    .ataglancebox{
        grid-template-columns: auto auto auto auto;
    }
}
@media (max-width:865px) {
    .ataglancebox{
        grid-template-columns: auto auto auto;
    }
}
@media (max-width:640px) {
    .ataglancebox{
        grid-template-columns:  auto auto;
        place-content: center;
    }
}
@media (max-width:1000px) {
    .whorv-center-bg{
        flex-direction: column !important;
    }
    .whorv-left-box{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .left-box-img-bg{
        width: 60% !important;
    }
    .whorv-right-box{
        width: 100% !important;
    }
    .right-box-text{
        margin: 0 !important;
    }

}
@media (max-width:825px) {
    .wwsgmSub{
        flex-direction: column !important;
    }
    .wwsgm-right{
        width: 100% !important;

    }
    .globeDiv{
        width: 100% !important;
    } 
}
.our-partners-logo{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    column-gap: 1rem;
    row-gap: 2rem;
}
/* @media (max-width:1155px) {
    .our-partners-logo{
        grid-template-columns: auto auto auto auto;
        place-content: center;
    }
} */
/* @media (max-width:925px) {
    .our-partners-logo{
        grid-template-columns: auto auto auto;
    }
} */
/* @media (max-width:685px) {
    .our-partners-logo{
        grid-template-columns: auto auto;
    }
} */
.our-university-logos{
    overflow-x: scroll;
    column-gap: 1rem;
}
.our-university-logos::-webkit-scrollbar{
    display: none;
}
.all-reviews{
    display: grid;
    grid-template-columns: auto auto auto;
    padding: 0 !important;
}
@media (max-width:1138px) {
 .all-reviews{
    grid-template-columns: auto auto;
 }   
}
@media (max-width:765px) {
    .all-reviews{
       grid-template-columns: auto;
    }   
   }
.corp-client-logos{
    display: flex;
    column-gap: 1rem;
    row-gap: 1rem;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}
/* slider */
/* .slick-slide {
    margin: 0 2%;
} */

/* the parent */
/* .slick-list {
    margin: 0 -27px;
} */

.reviewAll{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    column-gap: 1rem;
    row-gap: 1rem
    ;
}
.reviewCard:hover{
    box-shadow: 0 0 11px rgba(33,33,33,.2); 

}
@media (max-width:420px) {
    .left-box-img-bg{
        transform: scale(0.9) !important;
    }
}
@media (max-width:365px) {
    .left-box-img-bg{
        transform: scale(0.8) !important;
        margin-top: -2rem;
    }
}
@media (max-width:322px) {
    .left-box-img-bg{
        transform: scale(0.7) !important;
    }
}
.comps{
    position: absolute;
    background: #FFFFFF;
    box-shadow: 2px 3px 32px rgb(156 157 167 / 25%);
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.comps span{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 2rem;
    line-height: 38px;
    color: #DB3656;
    margin: 0;
}
.comps p{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #303030;
    margin: 0;
    font-size: 1rem;
    font-size: 1rem;
}
.asiaComp{
    top: 10%;
    left: -2%;
    padding: 2% 4% ;
}
.restComp1{
    bottom: 7%;
    left: 10%;
    padding: 2% 4%;
}
.restComp2{
    bottom: 11%;
    right: 25%;
    padding: 2% 4%;
}
.africaComp{
    right: -2%;
    top: 30%;
    padding: 2% 4%;
}
@media (max-width:1115px) {
    .africaComp{
        top: 10%;
    }
}
@media (max-width:910px) {
    .africaComp{
        top: 0;
    }
}
@media (max-width:825px) {
    .africaComp{
        top: -2%;
        right: 5%;
    } 
}
@media (max-width:550px) {
    .comps{
        transform: scale(0.8) !important;
    }
    .africaComp{
        top: -8%;
    }
    .restComp2{
        right: 0;
        bottom: -5%;
    }
    .restComp1{
        bottom: -5%;
    }
    .asiaComp{
        top: -5%;
        left: 0;
    }
}
@media (max-width:320px) {
    .comps{
        transform: scale(0.6) !important;
    }
}
.our-partners-logo{
    column-gap: 0;
    row-gap: 1rem;
    justify-content: center !important;
    transform: scale(0.87) !important;
    column-gap: 1rem;
}
.bottom-logo{
    border-radius: inherit;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
}
.bottom-logo p{
    font-size: 1rem !important;
    color: #7e7e7e !important;

}
/* new added pbt*/
.top-logo img {
    width: 90%;
    height: auto;
}

.our-partner-logo:hover{
    box-shadow: 0 0 15px rgb(33 33 33 / 40%)!important;
}
.our-university-logo{
    width: 90% !important;
    display: flex;
    align-items: center !important;
    justify-content: center !important;
    aspect-ratio: 1/0.5;
    min-width: 9rem;
}
.our-university-logo img{
    width: 90% !important;
    min-width: 8rem;
    object-fit: contain !important;
}
.reviewAll{
    width: 85% !important;
    justify-content: space-between;
}

.reviewCard:hover{
  
     background-color: #285F71;
    border: 1px solid #cccc;
    border-radius: 10px;
    color: #FFFFFF;
}
.reviewCard{
    width: 31%;
    min-width: 100% !important;
    border: 1px solid #cccc;
    border-radius: 10px;
    text-align: start;
    padding:1% 2% 1% 2%;
    transition: 1s;
    display: flex;
    flex-direction: column;
    min-height: 16rem;
    min-width: 26rem;
    padding-left: 2rem;
    
}
.reviewCard h3{
    line-height: 1.5;
}
.reviewCard p{
    font-size: 1.2rem;
    font-style: italic;
    margin-top: 0;

}
.reviewCard:hover p{
color: #FFFFFF;

}
.reviewCard:hover h3{
    color: #FFFFFF;
    
    }
.reviewCard:hover .card_button{
        color: #285F71;
        background-color: #FFFFFF;
        
}
.reviewCard:hover p .media{
    width: 10rem;
    
}
.button_div{
    
    margin-top: 2rem;
}
.card_button{

        background-color: #285F71;
        color: white;
        padding: 7px;
        margin-top: 7px;
       
       
      
}
.reviewCard span{
    width: fit-content !important;
}
@media (max-width:998px) {
    .reviewCard{
        width: 40%;
    }
    
}
@media (max-width:659px) {
    .reviewCard{
        width: 100%;
        min-width: 14rem;
        min-height: 16rem;
    }
    .reviewAll{
        justify-content: center;
    }
    
}
@media (max-width:340px) {
    .reviewCard{
        min-width: 10rem;
    }
}
.ataglancebox{
    column-gap: 0.5rem;
}
.aag-box{
    width: 19%;
    min-width: 11rem;
    max-width: 16rem;
}
@media (max-width:1073px) {
    .aag-box{
        width: 22%;
    }
}
@media (max-width:856px) {
    .aag-box{
        width: 28%;
    }
}
@media (max-width:640px) {
    .aag-box{
        width: 40%;
    }
    .ataglancebox{
        justify-content: space-between;
    }
}
@media (max-width:425px) {
    .aag-box{
        min-width: 10rem;
    }
}
@media (max-width:386px) {
    .ataglancebox{
        justify-content: center !important;
    }
    
}
.our-partner-logo{
    width: 20%;
    min-width: 11rem;
    max-width: 22rem;
}
.our-partner-logoDiv{
    column-gap: 0.5rem;

}
@media (max-width:857px) {
    .our-partner-logo{
        width: 30%;
    }
}
@media (max-width:649px) {
    .our-partner-logo{
        width: 40%;
    }
}
@media (max-width:425px) {
    .bottom-logo p{
        font-size: 0.8rem !important;
    }
    .our-partner-logo{
        min-width: 9rem;
    }
}
@media (max-width:350px) {
    .our-partner-logoDiv{
        justify-content: center !important;
    }
}

.cc-logo{
    width: 20% !important;
    padding: 3% 1%;
    min-width: 9rem;
}
.cc-logo img{
    width: 70%;
    height: 4rem;
    object-fit: contain;
}
.cc-logo-empty{
    width: 9% !important;
    padding: 5.5% 0;
    height: 4rem;
}
.corp-client-logos{
    width: 85% !important;
    justify-content: space-between;
}
@media (max-width:828px) {
    .cc-logo-empty{
        display: none;
    }
    .cc-logo{
        width: 24% !important;
    }
    .corp-client-logos{
        column-gap: 0.5rem;
    }
}
@media (max-width:706px) {
    .cc-logo{
        width: 32% !important;
    }
}
@media (max-width:526px) {
    .cc-logo{
        width: 48% !important;
    }
}

@media (max-width:348px) {
    .corp-client-logos{
        justify-content: center !important;
    }
    .cc-logo{
        width: 80% !important;
    }
}
.afterHeroSlider .slick-arrow{
    top: 185% !important;
}
.afterHeroSlider .slick-prev{
    left: 0;
}
.institutionSingleSlide{
    width: 100%;
    height:5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.bannerSlider2 {
    margin-left: 10%;
    margin-top: -60px;
    font-weight: 700;
    margin-top: -4rem;
    font-family: 'Montserrat';
    font-style: normal;
    font-size: 4rem !important;
    /*  line-height: 5rem; */

}
.bannerSlider2 p{
    font-size: 2.4rem;
    color: rgb(206 206 206);
   
    padding-top: 1rem;
}
@media (min-width:1800px) {
    .redFooter{
        margin-bottom: -5%;
    }
    /* .topbar .top-btn{
        margin-right: 4em;
    } */
}
.bannerCont{
    margin-left: 10%;
   
}
.bannerCon{
   padding-top: 9rem;
   padding-left: 8%;
}
.bannerCon p{
    font-size: clamp(2rem,4vw,2.4rem) !important;
    font-weight: 700;
    margin-top: -4rem;
font-family: 'Montserrat';
font-style: normal;

font-size: 4rem !important;
line-height: 5rem;
color: #FFFFFF;


}
.bannerCont p{
    /* font-size: clamp(2rem,4vw,2.4rem) !important; */
    font-weight:700;
    margin-top: 12rem;

font-family: 'Montserrat';
font-style: normal;

font-size: 4rem !important;
line-height: 5rem;
color: #FFFFFF;

}
.hero-banner-slider{
    margin-top: -40px;
}
@media (max-width:600px) {
    .bannerCont p{
        margin-left: 5%;
        /* margin-top: 40%; */      
        font-size: 3rem !important;
        line-height: 4rem;
            }
            .bannerCon p{
                margin-left: 5%;
                /* margin-top: 40%; */      
                font-size: 3rem !important;
                line-height: 4rem;
                padding-left: 0rem !important;
                    }
}

@media (max-width:810px) {

    .hero-banner-slider{
        margin-top: 0;
    }
}
@media (max-width:695px) {
    .settingsNewsAndEvents .slick-list{
        padding-bottom: 3rem;
    }
}

