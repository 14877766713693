
.s-layout {
    display: flex;
    width: 100%;
    min-height: 100vh;
}

.s-layout__content {
    margin-top: 3%;
    justify-content: center;
    align-items:
        center;
    flex: 1;
}

/* Sidebar */
.s-sidebar__trigger {
    z-index: 2;
    position:
        fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 4em;
    background: #192b3c;
}

.s-sidebar__trigger>i {
    display: inline-block;
    margin: 1.5em 0 0 1.5em;
    color: #f07ab0;
}

.s-sidebar__nav {
    position: fixed;
    top: 0;
    left: -15em;
    overflow: hidden;
    transition: all .3s ease-in;
    width: 15em;
    height: 100%;
    background: #243e56;
    color: rgba(255, 255, 255, 0.7);
}

.s-sidebar__nav ul {
    position: absolute;
    top: 4em;
    left: 0;
    margin: 0;
    padding: 0;
    width: 15em;
}

.s-sidebar__nav ul li {
    width: 100%;
}

.s-sidebar__nav-link {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 4em;
}

.s-sidebar__nav-link em {
    position: absolute;
    top: 50%;
    left: 4em;
    transform:
        translateY(-50%);
}

.s-sidebar__nav-link:hover {
    background: #4d6276;
}

.s-sidebar__nav-link>i {
    position: absolute;
    top: 0;
    left: 0;
    display:
        inline-block;
    width: 4em;
    height: 4em;
}

.s-sidebar__nav-link>i::before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

/* Mobile First */
.s-layout__content {
    margin-left: 4em;
}

/* Sidebar */


.s-sidebar__nav {
    width: 4em;
    left: 0;
}
.navBarResponsive{
    width: 15em;
   
} 

.s-layout__content {
    margin-left: 15em;
}

/* Sidebar */


.s-sidebar__nav {
    width: 15em;
}

.s-sidebar__nav ul {
    top: 1.3em;
}
.navIcon{
    transform: translate(36%,81%);
}
    .navBarResponsive{
        width: 15em;
    }
    .notResp{
        width:4em
    }
/* .addBtn{
padding: 7px;
background-color: rgb(63, 63, 202);
color: white;
text-align: center;
border: none;
outline: none;
border-radius: 10px;
}
.uploadImg{

align-items: center;
padding-top: 10px;
border-radius: 16px;
}
.adminTable td,th{
vertical-align: middle;
text-align: center;

}
.tableMain::-webkit-scrollbar{
display: none;
} */