.institutionsPoints li{
    margin-top: 2rem;
}
@media (max-width:950px) {
    .heroSecImg{
        height: 386px;
        left: 10%;
    }
}
@media (max-width:780px) {
    .heroSecImg{
        height: 350px;
        left: 10%;
    }
}
@media (max-width:750px) {
    .our-institution-section1{
        flex-direction: column;
    }
    .heroSecText{
        width: 100% !important;
    }
    .our-institution-img{
        min-width: 19rem;
        align-self: start;
        margin-left: 10%;
    }
    .our-institution-img{
        width: 65% !important;
    }
    .heroSecImg{
        height: 25rem;
        left: 10%;
    }
}

@media (max-width:510px) {
    .heroSecImg{
        height: 23rem;
        left: 10%;
    }
    .zigzag3{
        display: none;
    }
}

@media (max-width:466px) {
    .our-institution-img{

        margin-left: 2%;
    }
}
.brnd-blocks-row{
    display: grid;
    grid-template-columns: auto auto auto;
    flex-wrap: wrap;
    column-gap: 1rem;
    row-gap: 1rem;
    
}
.brnd-block{
    width: 30.5%;
    height: fit-content;
    min-width: 20rem;
}
@media (max-width:1180px) {
    .brnd-blocks-row{
        grid-template-columns: auto auto;
        place-content: center;
        place-items: center;
    }
}
.missionPoints li{
    margin-top: 1rem;
}
@media (max-width:880px) {
    .visionMission{
        flex-direction: column;
        row-gap: 1rem;
        align-items: center;
    }
    .visionMission :nth-child(1){
        width: 90% !important;
    }
    .visionMission :nth-child(2){
        width: 90% !important;
    }
}
/* .leaders-pic{
    display: grid !important;
    grid-template-columns: auto auto auto;
} */
.leaders-row{
    display: grid;
    grid-template-columns: auto auto auto;
    height: fit-content;
    row-gap: 1rem;
    justify-content: center;
    width: 100%;
    column-gap: 1rem;
}
@media (max-width:1010px) {
    .leaders-row{
        grid-template-columns: auto auto;
    }
}
@media (max-width:640px) {
    .leaders-row{
        grid-template-columns:  auto;
    }
}
.our-partnersImg{
    position: absolute;
    bottom: 0px;
    /* top: 38px; */
    left: 21px;
    z-index: 2;
    width: 100%;
    transform: matrix(1, 0, 0, 1, 0, 0);
}
@media (max-width:889px) {
    .mediaCentreTop{
        flex-direction: column;
        align-items: center;
        justify-content: center;
        row-gap: 2rem;
    }
    .mediaCentreTop2{
        width: 100% !important;
    }
    .mediaCentreTop1{
        width: 100% !important;
        min-width: 15rem !important;
    }
}

.articlesSection{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    column-gap: 1rem;
    row-gap: 1rem;
    margin-top: 2rem;
}
/* .ma-list-row{
    display: flex !important;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 98% !important;
} */
.rowNews{
    display: flex;
    flex-wrap: wrap;
    column-gap: 1rem;
    row-gap: 1rem;
    justify-content: center;
    align-items: center;
}

@media (max-width:540px) {
    .newsCard{
        width: 80% !important;
        flex-direction: column;
        padding-bottom:1rem ;
    }
    .newsCard div{
        width: 100% !important;
    }
}
.articleCard{
    transition: 0.3s;
}
.articleCard:hover{
    transform: scale(0.95);
}
.newsCard{
    transition: 0.3s;
    overflow: hidden;
}
.newsCardImg{
    transition: 0.3s;
}
.newsCard:hover .newsCardImg{
    transform: scale(1.1);
}

.zigZag2{
    position: absolute;
    top: 96%;
    left: 111%;
    transform: translate(-50%, -50%);
    width: 20%;
}
.zigZag1{
    position: absolute;
    top: 25%;
    left: 5%;
    transform: translate(-50%, -50%);
    width: 20%;
}
.backgroundLayer{
    top: 10%;
    left: 0;
             
}
.borderLayer{
    top: 0;
    right: 0;
}                
.heroImgStartingSec{
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 80%;
}                                     
@media (max-width:1100px) {
    .startingContentContainer{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }                      
    .startingContentText{
        width: 100% !important;
        margin-top: 3rem;
    }
    .startingContentImg{
        width: 50% !important;
    }
    .startingContentText div{
        width: 100% !important;
    }
                
}
@media (max-width:500px) {
    .startingContentImg{
        width: 90% !important;
        min-width: 18rem !important;
    }
}  

.leaders-img-text{
    /* height: 520px; */
    height:100%;
}

.leaders-img-text img{
    width: 100%;
}

.leaders-indiv-bottom{
    width: 100%;
}

@media (max-width:770px) {
    .leadership-headings{
        width: 90% !important;
    }
}
.leaders-indiv-bottom{
    text-align: left;
    padding: 0 10% 0 5%;
}
.leaders-img-text img{
    border: 1px solid #D9D9D9;
}
.leaders-row{
    row-gap: 3rem;
}
.leaders-indiv-bottom {
    transition: 0.3s;
}
.leaders-img-text:hover .leaders-indiv-bottom {
    background-color: #285F71;
}
.leaders-img-text:hover .leaders-indiv-bottom h3{
    color: white !important;
}
.leaders-img-text:hover .leaders-indiv-bottom p{
    color: white !important;
}
.articleCard{
    border-radius: 5%;
    background-position: center center !important;
    background-size: cover !important;
    display: flex;
    flex-direction: column;
    width: 95%;
    background-repeat: no-repeat !important;
    max-width: 30rem;
}
.articleCard span{
    width: fit-content;
}
.articleCard p{
    margin: 0;
}

@media (max-width:700px) {
    .articleCard{
        width: 100%;
    }
}
.milStoneSliderSingle{
    box-shadow: 0px 49px 40px rgba(226, 226, 226, 0.47);
    display: flex;
    margin-bottom: 3%;
    transition: 0.4s;
    padding-top: 7rem !important;
}
.milStoneSliderSingleSub1{
    padding: 10%;
}
.milStoneSliderSingleSub2{
    padding: 5%;
    background-color: #FFFF;
}
@media (max-width:850px) {
    .milStoneSliderSingle{
        flex-direction: column;
    }
    .milStoneSliderSingleSub1,.milStoneSliderSingleSub2{
        width: 100% !important;
    }
}
.mileStoneSlider .slick-next{
    top: 20% !important;
    left: 105% !important;
}
.mileStoneSlider .slick-prev{
    top: 43% !important;
    left: 105% !important;
}
.mileStoneSlider .slick-slide img{
    display: inline !important;
}
.overlaySmallSlider{
    transition: 0.5s;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.institutionList li{
    margin-top: 1rem;
    line-height: 23px;
    font-size: 0.9rem;
    text-align: justify;
}
.institutionList {
    margin-bottom: 0;
}
.brnd-block h3{
    margin-bottom: 10px;
    margin-top: 10px;
    line-height: normal;
}
.brnd-block{
    text-align: start;
    padding: 20px 30px;
    min-width: 18rem;
}
.brnd-block p{
    line-height: 23px;
    hyphens: auto;
}
.brnd-block h4{
   margin: 0;
}
@media (max-width:996px) {
    .brnd-block{
        width: 48%;
    }
}
@media (max-width:658px) {
    .brnd-block{
        width: 100%;
    }
    .brnd-blocks-row{
        justify-content: center !important;
    }
}
.single-trophy {
    width: 24%;
    height: fit-content;
    min-width: 14rem;
}
.single-trophy-txt{
    width: 100%;
    height: fit-content;
    position: static;
    padding: 9%;
    text-align: start;
}
.single-trophy img{
    width: 100%;
}
.single-trophy:hover{
    transform: scale(0.95) !important;
}
@media (max-width:1023px) {
    .single-trophy{
        width: 32%;
    }
}
@media (max-width:765px) {
    .single-trophy{
        width: 48%;
    }
}
@media (max-width:507px) {
    .single-trophy{
        width: 80%;
    }
    .trophy-display-row{
        justify-content: center !important;
    }
}
.newsCard{
    width: 95%;
    min-width: 25rem;
}
.newsCard img{
    object-fit: cover;
    object-position: center center;
}
@media (max-width:948px) {
    .newsCard{
        width: 90%;
    }
    .rowNews{
        justify-content: center !important;
    }

}
@media (max-width:620px) {
    .newsCard{
        flex-direction: column !important;
        min-width: 10rem;
        width: 100% !important;
    }
    .newsCard1{
        aspect-ratio: 1/0.8;
        min-width: 100%;
        
    }
    .newsCard div{
        width: 100% !important;
    }
}
@media (max-width: 450px){
    .newsCard{
       
       
       
    }
}
.filterBtns span{
    padding: 15px;
    width: 25%;
    transition: 0.3s;
    cursor: pointer;
    text-align: center;
}
.filterBtns span:hover{
    background-color: #285F71;
    color: white;
}
.filterCards{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    flex-wrap: wrap;
    margin-top: 5%;
    row-gap: 1rem;
    column-gap: 0.5rem;
}
.fiterCard{
    width: 19%;
    display: flex;
    flex-direction: column;
    background: white;
    border: 1px solid #DCDCDC;
    padding: 10px;
    border-radius: 10px;
    min-width: 11rem;
}
.fiterCard img{
    width: 70%;
}
.fiterCard p{
    font-size: 0.9rem;
}
@media (max-width:1013px) {
    .fiterCard{
        width: 24%;
    }
}
@media (max-width:809px) {
    .fiterCard{
        width: 32%;
    }
}
@media (max-width:735px) {
    .filterTopSec{
        flex-direction: column;
    }
    .filterTopSec div{
        width: 100% !important;
    }
}
@media (max-width:606px) {
    .fiterCard{
        width: 45%;
    }
}
@media (max-width:400px) {
    .fiterCard{
        width: 100%;
    }
    .filterCards{
        justify-content: center;
    }
}
.articleContents p{
    text-align: justify;
    line-height: 26px;
    font-size: 0.9rem;
}
.articleContents .articleCard{
    width: 100% !important;
    min-width: 15rem;
    border-radius: 4%;
}
@media (max-width:930px) {
    .articleContents{
        flex-direction: column;
    }
    .articleContentsSub1,.articleContentsSub2{
        width: 100% !important;
    }
}
@media (max-width:1100px) {
    .zigzagBlue{
        display: none;
    }
}
@media (max-width:945px) {
    .newsCard{
        width: 98% !important;
    }
}
.articleCard{
    position: relative;
}
.articleCard img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.articleCard{
    display: flex;
    align-items: start;
    justify-content: center;
}
.articleCardInner{
    width: 90%;
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    justify-content: space-between;
    padding: 3% 0;
    padding-left: 5%;
    min-height: 16rem;
}
.articleCardInner p{
    margin: 0 !important;
}
